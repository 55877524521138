<template>
  <div style="text-align: center">
    <a-card class="init-company-panel">
      <PersonalCenter />
    </a-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import PersonalCenter from "@/views/Personal/Info.vue";
export default {
  name: "InitCompany",
  components: {
    PersonalCenter,
  },
};
</script>

<style>
.init-company-panel {
  display: inline-block;
  margin-top: 5em;
  margin-bottom: 5em;
  min-height: calc(100vh - 5em);
  min-width: 1000px;
  text-align: unset;
}
</style>
